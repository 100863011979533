<template>
  <h2>登录页</h2>
  <div>仓库退货入库图片/视频上传</div>
  <el-form
    :rules="loginRules"
    ref="loginRef"
    :model="loginForm"
    style="margin-top: 80px"
  >
    <el-form-item prop="username">
      <el-input
        v-model="loginForm.username"
        type="text"
        auto-complete="off"
        placeholder="用户名"
      >
        <template #prefix
          ><svg-icon icon-class="user" class="el-input__icon input-icon"
        /></template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="loginForm.password"
        :type="isViews ? '' : 'password'"
        auto-complete="off"
        placeholder="密码"
      >
        <template #prefix
          ><svg-icon icon-class="password" class="el-input__icon input-icon"
        /></template>
        <template #suffix>
          <el-icon @click="viewsPassword(1)" v-if="isViews === false"
            ><View
          /></el-icon>
          <el-icon v-else @click="viewsPassword(2)"><Hide /></el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-checkbox
      v-model="loginForm.rememberMe"
      style="margin: 0px 0px 25px 0px;width: 100%;"
      >记住密码</el-checkbox
    >
  </el-form>
  <el-button @click="handleLogin">登录</el-button>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import useUserStore from "@/store/modules/user";

const userStore = useUserStore();
const router = useRouter();
const loginForm = ref({
  username: "",
  password: "",
  rememberMe: false,
  code: "",
  uuid: "",
});

const loginRules = {
  username: [{ required: true, trigger: "blur", message: "请输入您的账号" }],
  password: [{ required: true, trigger: "blur", message: "请输入您的密码" }],
  code: [{ required: true, trigger: "change", message: "请输入验证码" }],
};

const loading = ref(false);
// 注册开关
const redirect = ref(undefined);
const isViews = ref(false);

// 查看密码
function viewsPassword(item) {
  switch (item) {
    case 1:
      isViews.value = true;
      break;
    case 2:
      isViews.value = false;
      break;
    default:
      break;
  }
}
function handleLogin() {
  loading.value = true;
  // 勾选了需要记住密码设置在 cookie 中设置记住用户名和密码
  if (loginForm.value.rememberMe) {
    Cookies.set("username", loginForm.value.username, { expires: 30 });
    Cookies.set("password", encrypt(loginForm.value.password), {
      expires: 30,
    });
    Cookies.set("rememberMe", loginForm.value.rememberMe, { expires: 30 });
  } else {
    // 否则移除
    Cookies.remove("username");
    Cookies.remove("password");
    Cookies.remove("rememberMe");
  }
  // 调用action的登录方法
  userStore
    .login(loginForm.value)
    .then(() => {
      console.log(redirect.value, "vvvv.value");
      router.push({ path: "/uploadmaterial" });
    })
    .catch(() => {
      loading.value = false;
    });
}

function getCookie() {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  const rememberMe = Cookies.get("rememberMe");
  loginForm.value = {
    username: username === undefined ? loginForm.value.username : username,
    password:
      password === undefined ? loginForm.value.password : decrypt(password),
    rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
  };
}
getCookie();
</script>

<style></style>
