import { createRouter, createWebHistory } from "vue-router";
import HelloWorld from "../views/HelloWorld.vue";
import LoginIndex from "../views/LoginIndex.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: LoginIndex },
    { path: "/uploadmaterial", component: HelloWorld },
  ],
});
export default router