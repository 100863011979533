import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from "./store";
import router from "./utils/router";

// import locale from 'element-plus/lib/locale/lang/zh-cn' // 中文语言
const app = createApp(App);
// 使用element-plus 并且设置全局的大小
// 使用element-plus 并且设置全局的大小
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router);
app.use(ElementPlus);
app.use(store);
app.mount("#app");
