// 这个文件主要是对axios进行二次封装
// 因为原本的axios不能满足我们的功能需求
// 最终要达到
// 1、配置基础路径和超时限制
// 2、添加进度条信息  nprogress
// 3、返回的响应不再需要从data属性当中拿数据，而是响应就是我们要的数据
// 4、统一处理请求错误, 具体请求也可以选择处理或不处理
import { ElNotification } from "element-plus";
import axios from "axios";
import Nprogress from "nprogress"; //引入js
import "nprogress/nprogress.css"; //引入css
import { getToken } from "@/utils/auth";
// 1、配置基础路径和超时限制
const instance = axios.create({
  baseURL: "https://ckgreen-admin-api.haiyn.com",
  // baseURL: "http://cktest-admin-api.haiyn.com",
  timeout: 20000,
});

// 2、添加进度条信息  nprogress（开启进度条）
instance.interceptors.request.use(
  // 请求拦截器的成功
  (config) => {
    Nprogress.start();
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  }
);

// 3、返回的响应不再需要从data属性当中拿数据，而是响应就是我们要的数据
instance.interceptors.response.use(
  (response) => {
    Nprogress.done();
    if (response.data.code !== 200) {
      ElNotification.error({ title: response.data.msg });
      return Promise.reject("error");
    }
    return response.data;
  },
  (error) => {
    // 4、统一处理请求错误, 具体请求也可以选择处理或不处理
    Nprogress.done();
    alert("ajax请求失败");
    return Promise.reject(error); //让错误信息继续传递下去
    // return new Promise(() => {}) //返回pending状态的promise，可以中断promise链
  }
);

export default instance;
