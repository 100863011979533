<template>
  <div style="padding: 0 0 50px">
    <h2>物流单图片/视频上传</h2>
    <div v-show="isScaning">
      <video ref="video" id="video" class="scan-video" autoplay></video>
    </div>
    <el-input
      placeholder="请输入或扫描物流单号"
      ref="input1"
      class="mt20"
      v-model="form.logisticsNo"
    >
      <template #suffix>
        <svg
          @click="openScan"
          t="1728701859214"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2384"
          width="20"
          height="20"
        >
          <path
            d="M213.333333 426.666667V213.333333h213.333334v85.333334H298.666667v128H213.333333z m0 170.666666h85.333334v128h128v85.333334H213.333333v-213.333334z m597.333334-170.666666h-85.333334V298.666667h-128V213.333333h213.333334v213.333334z m0 170.666666v213.333334h-213.333334v-85.333334h128v-128h85.333334z m0-128v85.333334H213.333333v-85.333334h597.333334z"
            fill="#444444"
            p-id="2385"
          ></path>
        </svg>
      </template>
    </el-input>
    <!-- 上传图片上传视频dialog -->
    <el-form label-width="" class="mt20" style="width: 100%; overflow: auto">
      <el-form-item label="图片：">
        <el-upload
          accept=".jpg, .png, .jpeg,.JPG, .PNG, .JPEG"
          :disabled="uploadtitle === '查看' ? true : false"
          v-model:file-list="fileList"
          list-type="picture-card"
          :action="''"
          :http-request="UploadPictureFileFn"
          :show-file-list="true"
          :limit="10"
          :multiple="true"
        >
          <!-- :on-remove="handleRemove" -->
          <!-- :before-upload="beforeUpload" -->
          <!-- :on-preview="handlePictureCardPreview" -->
          <el-icon class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
        <div></div>
      </el-form-item>
      <el-form-item label="视频:" prop="" class="videoUpload">
        <el-upload
          drag
          v-model:file-list="videofileList"
          class="upload-demo"
          :on-success="handlePictureCardSuccess"
          :show-file-list="true"
          :http-request="UploadFileFn"
          :on-progress="(event) => uploadVideoProcess"
          :on-remove="handleRemovevideo"
          :action="''"
          :data="uploadImgData"
          accept=".mp4, .ogg"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text"><em>点击上传</em></div>
          <template #tip>
            <div class="el-upload__tip">
              视频上传成功有成功提示，请在视频上传成功后再进行提交
            </div>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-button class="mt20" @click="reset"> 重置 </el-button>
    <el-button type="primary" class="mt20" @click="uploadsubmitForm">
      提 交
    </el-button>
  </div>
</template>
<script setup>
import { getOssTempToken, returnWarehouseImg } from "@/api/index";
import OSS from "ali-oss";
import { onMounted, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { BrowserMultiFormatReader } from "@zxing/library";
const codeReader = ref(null);
const isScaning = ref(false);
//上传dialog数据
// const uploadImgUrl = ref(import.meta.env.VITE_APP_BASE_API + "/upload/picture"); // 上传的图片服务器地址
// const uploadImgUrl = ref("https://ckgreen-admin-api.haiyn.com/upload/picture"); // 上传的图片服务器地址
const form = ref({
  logisticsNo: "",
});
const fileList = ref([]);
const videofileList = ref([]);
const uploadtitle = ref("");
const OssTempInfo = ref({});
const input1 = ref(null);
watch(
  form.value,
  (n, o) => {
    console.log(n, o);
    isScaning.value = false;
  },
  {
    deep: true, // 深度侦听, 监听ref创建的响应式对象必须开启
  }
);
onMounted(() => {
  input1.value?.focus();
  OssTempToken();
});

// 开启扫码
const openScan = () => {
  isScaning.value = true;
  codeReader.value = new BrowserMultiFormatReader();
  codeReader.value
    .getVideoInputDevices()
    .then((videoDevices) => {
      let firstDeviceId = videoDevices[videoDevices.length - 1].deviceId;
      if (videoDevices.length > 1) {
        // 一般通过判断摄像头列表项里的 label 字段，'camera2 0, facing back' 字符串含有 'back' 和 '0'，大部分机型是这样，如果有些机型没有，那就还是默认获取最后一个
        firstDeviceId = videoDevices.find((el) => {
          return el.label.indexOf("back") > -1 && el.label.indexOf("0") > -1;
        })
          ? videoDevices.find((el) => {
              return (
                el.label.indexOf("back") > -1 && el.label.indexOf("0") > -1
              );
            }).deviceId
          : videoDevices[videoDevices.length - 1].deviceId;
      }
      decodeFromInputVideoFunc(firstDeviceId);
    })
    .catch((err) => {
      alert(err);
      // isScaning.value = false;
    });
};

// 扫码
const decodeFromInputVideoFunc = (firstDeviceId) => {
  // 使用摄像头扫描
  codeReader.value.reset(); // 重置
  codeReader.value.decodeFromInputVideoDeviceContinuously(
    firstDeviceId,
    "video",
    (result) => {
      if (result) {
        form.value.logisticsNo = result.text;
        if (form.value.logisticsNo) {
          // 识别成功关闭摄像头
          codeReader.value.reset();
          codeReader.value.stopContinuousDecodeFromInputVideoDevice();
        }
      }
    }
  );
};
// 获取oss信息
async function OssTempToken() {
  const res = await getOssTempToken();
  if (res.code === 200) {
    OssTempInfo.value = res.data;
  }
}
// 重置
function reset() {
  input1.value?.focus();
  form.value = {};
  fileList.value = [];
  videofileList.value = [];
  isScaning.value = false;
}
// 上传图片 提交按钮
async function uploadsubmitForm() {
  // 赋值url
  // fileList.value = fileList.value.map((item) => {
  //   return {
  //     url: item.url,
  //   };
  // });
  console.log(fileList.value, "3232324");
  const param = {
    imgUrl: JSON.stringify(fileList.value),
    videoUrl: JSON.stringify(videofileList.value),
    logisticsNo: form.value.logisticsNo,
  };
  console.log(param, "paramparam");
  const res = await returnWarehouseImg({ ...param });
  if (res.code === 200) {
    ElMessage({
      message: "提交成功",
      type: "success",
    });
    fileList.value = [];
    videofileList.value = [];
    reset();
  }
}

// 上传视频------------------------------------------
// 上传视频------------------------------------------

// 移出照片时的钩子
function handleRemovevideo(file, fileList) {
  fileList.value = [...fileList];
}

function uploadVideoProcess(event) {
  console.log(event);
}
function UploadFileFn() {
  let client = new OSS({
    // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: "oss-cn-hangzhou",
    // 从环境变量中获取访问凭证
    // 当然，以下两个也可以直接写死
    accessKeyId: OssTempInfo.value.accessKeyId,
    accessKeySecret: OssTempInfo.value.accessKeySecret,
    stsToken: OssTempInfo.value.securityToken,
    expiration: OssTempInfo.value.expiration,
    // 填写Bucket名称。
    bucket: "img-scrm",
    requestId: OssTempInfo.value.requestId,
  });
  const put = async () => {
    try {
      console.log(videofileList.value, "EEE");
      let result = await client.put(
        `/videos/${Date.parse(new Date())}`,
        videofileList.value[videofileList.value.length - 1].raw
      );
      if (result.res.statusCode === 200) {
        console.log("上传阿里云成功", result, videofileList.value);
        ElMessage({
          message: "视频上传成功",
          type: "success",
        });
        videofileList.value[videofileList.value.length - 1].url = result.url;
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };
  put();
}
// 图片
function UploadPictureFileFn(raw) {
  let client = new OSS({
    // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: "oss-cn-hangzhou",
    // 从环境变量中获取访问凭证
    // 当然，以下两个也可以直接写死
    accessKeyId: OssTempInfo.value.accessKeyId,
    accessKeySecret: OssTempInfo.value.accessKeySecret,
    stsToken: OssTempInfo.value.securityToken,
    expiration: OssTempInfo.value.expiration,
    // 填写Bucket名称。
    bucket: "img-scrm",
    requestId: OssTempInfo.value.requestId,
  });
  const put = async () => {
    console.log(Date.parse(new Date()));
    try {
      let result = await client.put(
        `/Picture/${Date.parse(new Date()) + raw.file.uid}`,
        raw.file
      );
      if (result.res.statusCode === 200) {
        ElMessage({
          message: "图片上传成功",
          type: "success",
        });
        const row = fileList.value.find((item) => {
          if (item.uid === raw.file.uid) {
            return item;
          }
        });
        if (row) {
          row.url = result.url;
        }
      }
    } catch (e) {
      alert(e);
    }
  };
  put();
}

function handlePictureCardSuccess() {}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}

/*设置滚动条样式*/
::-webkit-scrollbar-thumb {
  background-color: #979e9f;
}
::v-deep .videoUpload .el-upload-list__item {
  width: 77vw;
}

/* 相关样式 */

video {
  margin-top: 20%;
  width: 80%;
  /* background: red; */
}
.but_box {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.but_box div {
  display: flex;
  margin: 0.125rem 0;
  justify-content: space-around;
}
.but_box div span {
  width: 5.625rem;
  text-align: right;
}
.but_box div input {
  text-align: center;
}
.but_box div button {
  width: 8.4375rem;
  height: 2.75rem;
  font-size: 1.5625rem;
  font-weight: 600;
  color: lavenderblush;
  background-color: rgba(0, 183, 255, 0.8);
  border: none;
  border-radius: 0.5rem;
}
</style>
